<script setup lang="ts">
import { dateDeDE, deDE } from 'naive-ui'
import { appName } from '~/constants'
import { themeOverrides } from './theme.config'

useHead({
  title: appName,
})
</script>

<template>
  <div>
    <VitePwaManifest />
    <naive-config
      :theme-config="themeOverrides"
      :locale="deDE"
      :date-locale="dateDeDE"
    >
      <n-loading-bar-provider>
        <naive-loading-bar navigation />
      </n-loading-bar-provider>
      <NMessageProvider>
        <NDialogProvider>
          <NModalProvider>
            <NuxtLayout>
              <NuxtPage class="h-screen w-full overflow-y-auto" />
            </NuxtLayout>
          </NModalProvider>
        </NDialogProvider>
      </NMessageProvider>
    </naive-config>
  </div>
</template>

<style>
html,
body,
#__nuxt {
  height: 100vh;
  margin: 0;
  padding: 0;
}

html.dark {
  background: #222;
  color: white;
}

.n-tabs-nav {
  @apply shadow-md;
}
</style>
