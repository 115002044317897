<script setup lang="ts">
import type { NuxtError } from '#app'
import * as Sentry from '@sentry/nuxt'
import { themeOverrides } from './theme.config'

const props = defineProps({
  error: Object as () => NuxtError,
})

const errorTitle = computed(() => {
  switch (props.error?.statusCode) {
    case 403:
      return 'Fehler 403: Nicht berechtigt'
    case 404:
      return 'Fehler 404: Seite existiert nicht'
    default:
      return `Fehler ${`${props.error?.statusCode}: ` || ''}Unerwarteter Fehler`
  }
})

const errorDescription = computed(() => {
  switch (props.error?.statusCode) {
    case 403:
      return 'Du scheinst eine Ressource angefordert zu haben, auf die Du keinen Zugriff hast.'
    case 404:
      return 'Die angeforderte Seite scheint nicht zu existieren.'
    default:
      return `Wir wissen selbst nicht genau, wie es zu diesem Fehler kommen konnte. Bitte versuche es später erneut.`
  }
})

useHead({
  title: `${errorTitle.value}`,
})

const handleError = () => clearError({ redirect: '/' })

async function openSentry() {
  const feedback = Sentry.feedbackIntegration({ autoInject: false })
  const form = await feedback.createForm()
  form.appendToDom()
  form.open()
}
</script>

<template>
  <naive-config :theme-config="themeOverrides">
    <n-loading-bar-provider>
      <naive-loading-bar navigation />
    </n-loading-bar-provider>
    <div class="h-screen w-screen flex flex-col items-center justify-center pa-5 space-y-8">
      <div class="flex items-center space-x-4">
        <img
          src="/maskable-icon.png"
          alt="trackIT Logo"
          title="trackIT"
          class="w-20"
        >
        <span class="text-3xl font-semibold">trackIT</span>
      </div>
      <NCard
        size="huge"
        class="max-w-full w-2xl"
      >
        <template #header>
          <div class="text-4xl">
            {{ errorTitle }}
          </div>
        </template>
        <div class="space-y-6">
          <section class="text-lg line-height-relaxed">
            {{ errorDescription }} <Icon name="i-mdi-emoticon-cry-outline" class="text-2xl" />
            <p class="mt-4">
              Wenn Du denkst, dass es dabei nicht mit rechten Dingen zugeht, melde uns das bitte. Halte dazu bitte möglichst genau fest, wie es zu dem Fehler gekommen ist.
            </p>
          </section>
          <section class="flex flex-col flex-wrap sm:flex-row sm:items-center sm:justify-end space-y-4 sm:space-x-4 sm:space-y-0">
            <NButton

              text primary
              size="large"
              @click="handleError"
            >
              <template #icon>
                <Icon name="i-ic-baseline-check" />
              </template>
              Verstanden, zurück zur Seite!
            </NButton>
            <NButton
              tertiary
              type="error"
              size="large"
              @click="openSentry"
            >
              <template #icon>
                <Icon name="i-fa6-solid-bullhorn" />
              </template>
              Einen Fehler melden
            </NButton>
          </section>
        </div>
      </NCard>
    </div>
  </naive-config>
</template>
