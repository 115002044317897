import revive_payload_client_nrIgWCNagU from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EZxaqC13ya from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RPAAc3pOJh from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_PC1Di528F0 from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Cl7dPrV3yT from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_i8P5LSC3DA from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_7kNkTs0z7S from "/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.34.8_typescript@5.6.2_vue@3.4.36_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_UdRm8tEYuO from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_m6CNawicw9 from "/frontend/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._r5kof6l7sjocor6ysv7fv2i76a/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/frontend/.nuxt/sentry-client-config.mjs";
import colorMode_yQLyImI1j6 from "/frontend/node_modules/.pnpm/@bg-dev+nuxt-naiveui@1.14.0_patch_hash=k2aqr27o2iepjltu3xx42spbtm_magicast@0.3.5_rollup@4.34._zuodjo67d22uwos2hcri5bwwwm/node_modules/@bg-dev/nuxt-naiveui/dist/runtime/plugins/colorMode.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_uoq8AzTDwl from "/frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.34.8_vite@5.4.14_@types+node@22.13.5_terser@5.3_cojape4apt6tyhsxepykgf7xa4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_ab92s73O91 from "/frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.34.8_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/frontend/.nuxt/unocss.mjs";
import api_GFfDXud5Cr from "/frontend/plugins/api.ts";
import authorization_llqo4Qgkpk from "/frontend/plugins/authorization.ts";
export default [
  revive_payload_client_nrIgWCNagU,
  unhead_EZxaqC13ya,
  router_RPAAc3pOJh,
  navigation_repaint_client_PC1Di528F0,
  check_outdated_build_client_Cl7dPrV3yT,
  chunk_reload_client_i8P5LSC3DA,
  plugin_vue3_7kNkTs0z7S,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UdRm8tEYuO,
  sentry_client_m6CNawicw9,
  sentry_client_config_o34nk2sJbg,
  colorMode_yQLyImI1j6,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_uoq8AzTDwl,
  plugin_client_ab92s73O91,
  unocss_MzCDxu9LMj,
  api_GFfDXud5Cr,
  authorization_llqo4Qgkpk
]