import type { AbilityClass, AbilityOptionsOf, MongoQuery } from '@casl/ability'
import { AbilityBuilder, fieldPatternMatcher, mongoQueryMatcher, PureAbility } from '@casl/ability'

import type { AppAbility } from '~/types/authorization.type'
import type { Invoice } from '~/types/invoice.type'
import type { Project } from '~/types/project.type'
import type { Report } from '~/types/report.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'
import type { TimeEntry } from '~/types/timeEntry.type'
import type { User } from '~/types/user.type'

const Ability = PureAbility as AbilityClass<AppAbility>
const abilityOptions: AbilityOptionsOf<AppAbility> = { conditionsMatcher: mongoQueryMatcher, fieldMatcher: fieldPatternMatcher }

export function appAbility(user?: User) {
  const { can, cannot, build } = new AbilityBuilder<AppAbility>(Ability)

  if (!user)
    return build(abilityOptions)

  const roles = user.roles.map(({ name }) => name)

  const isAdmin = roles.includes('administrator')

  /* GENERAL RULES */

  /* Dashboard rules */
  can('read', 'dashboard')

  /* Settings rules */
  can('read', 'settings')

  /* Project rules */
  can('read', 'projects')
  can('read', 'projects-id-view')

  /* Report rules */
  can('read', 'reports')
  can('read', 'reports-id-view')
  can('create', 'report')

  /* TimeEntry rules */
  can('read', 'timesheets')

  if (isAdmin) {
    /* ADMIN RULES */

    /* Dashboard rules */
    can('read', 'dashboard-admin-metrics')

    /* Client rules */
    can('read', 'clients')
    can('read', 'clients-id-view')
    can(['create', 'read', 'update', 'delete', 'restore'], 'client')

    /* Project rules */
    can(['create', 'update', 'delete', 'restore'], 'project')

    /* Invoice rules */
    can('read', ['invoices', 'invoices-id'])
    can(['create', 'read', 'update', 'delete', 'restore'], 'invoice')

    /* Recurring invoice rules */
    can('read', ['recurring-invoices', 'recurring-invoices-id'])
    can(['create', 'read', 'update', 'delete', 'restore'], 'recurring-invoice')

    /* Report rules */
    can(['read', 'update', 'delete', 'restore'], 'report')

    /* Task rules */
    can(['create', 'read', 'update', 'delete', 'restore'], 'task')

    /* TaskList rules */
    can(['create', 'read', 'update', 'delete', 'restore'], 'task-list')

    /* TimeEntry rules */
    can(['create', 'read', 'update', 'delete', 'restore'], 'time-entry')
  }
  else {
    const isHR = roles.includes('hr')
    const isTeamLead = roles.includes('team_lead')
    const isAccounting = roles.includes('accounting')
    /* employee permissions */

    /* Project rules */
    can('update', 'project', {
      'leads.id': user.employee.id,
    } as MongoQuery<Project>)

    /* Report rules */

    type ReportQuery = MongoQuery<Report<'withEmployee'>>

    can(['read', 'update', 'delete'], 'report', {
      employeeId: user.employee.id,
    } as ReportQuery)

    /* Task rules */
    type TaskQuery = MongoQuery<Task>
    type TaskKeys = keyof Task | Array<keyof Task>
    can('create', 'task')
    cannot('create', 'task', ['isBillable', 'timeLimitSec', 'notificationThresholds', 'isCompleted'] as TaskKeys, {
      'project.leads.id': {
        $ne: user.employee.id,
      },
    } as TaskQuery)

    can('update', 'task', {
      'project.leads.id': user.employee.id,
    } as TaskQuery)

    can(['delete', 'restore'], 'task', {
      'taskList.project.leads.id': user.employee.id,
    } as TaskQuery)

    can(['delete', 'restore'], 'task', {
      'project.leads.id': user.employee.id,
    } as TaskQuery)

    /* TaskList rules */
    type TaskListQuery = MongoQuery<TaskList>
    type TaskListKeys = keyof TaskList | Array<keyof TaskList>
    can(['create', 'update'], 'task-list')
    cannot('update', 'task-list', 'isCompleted' as TaskListKeys, {
      'project.leads.id': {
        $ne: user.employee.id,
      },
    } as TaskListQuery)

    can(['delete', 'restore'], 'task-list', {
      'project.leads.id': user.employee.id,
    } as TaskListQuery)

    /* TimeEntry rules */
    type TimeEntryQuery = MongoQuery<TimeEntry>
    type TimeEntryKeys = keyof TimeEntry | Array<keyof TimeEntry>

    /* Employees */
    can(['read', 'create', 'update', 'delete'], 'time-entry', {
      employeeId: user.employee.id,
    } as TimeEntryQuery)

    /* Leads */
    can(['read', 'create'], 'time-entry', {
      'project.leads.id': user.employee.id,
    } as TimeEntryQuery)
    can('update', 'time-entry', ['taskId', 'projectId'] as TimeEntryKeys, {
      'employeeId': {
        $ne: user.employee.id,
      },
      'project.leads.id': user.employee.id,
    } as TimeEntryQuery)

    /* HR */
    if (isHR)
      can('read', 'time-entry')

    // Deny admin-only fields
    cannot(['create', 'update'], 'time-entry', ['isBilled', 'employeeId'] as TimeEntryKeys)

    /* Invoice rules */

    type InvoiceQuery = MongoQuery<Invoice>

    if (isAccounting) {
      can('read', ['invoice', 'invoices', 'invoices-id'])
    }

    if (isTeamLead) {
      can('create', 'invoice')
      can('read', ['invoices', 'invoices-id'])
      can(['read', 'update'], 'invoice', {
        creatorId: user.employee.id,
      } as InvoiceQuery)
    }

    /* RecurringInvoice rules */

  //   TODO: remove if it turns out that both hr and team_lead do not need access to recurring invoices
  //   type RecurringInvoiceQuery = MongoQuery<RecurringInvoice>
  //
  //   if (isAccounting) {
  //     can('read', ['recurring-invoice', 'recurring-invoices', 'recurring-invoices-id'])
  //   }
  //   else if (isTeamLead) {
  //     can('create', 'recurring-invoice')
  //     can('read', ['recurring-invoices', 'recurring-invoices-id'])
  //     can(['read', 'update'], 'recurring-invoice', {
  //       employeeId: user.employee.id,
  //     } as RecurringInvoiceQuery)
  //   }
  }

  return build(abilityOptions)
}
