import validate from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/pages/runtime/validate.js";
import authentication_45global from "/frontend/middleware/authentication.global.ts";
import authorization_45global from "/frontend/middleware/authorization.global.ts";
import manifest_45route_45rule from "/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.13.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1_eb2agp2mkub3rie2zdrgvrxehu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authentication_45global,
  authorization_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}