import type { UseFetchOptions } from '#app'
import { defu } from 'defu'

export function useApiFetch<TRes, TData = TRes>(url: MaybeRefOrGetter<string>, options: UseFetchOptions<TRes, TData> = {}) {
  const { $api } = useNuxtApp()

  const defaults: UseFetchOptions<TRes, TData> = {
    deep: false,
    $fetch: $api,
    headers: useApiRequestHeaders().value,
  }

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults)

  return useFetch(url, params)
}
