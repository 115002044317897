export default function () {
  const requestURL = useRequestURL()

  const csrfToken = useCookie('XSRF-TOKEN')

  return computed<HeadersInit>(() => {
    return {
      ...useRequestHeaders(['referer', 'cookie']),
      'accept': 'application/json',
      'origin': requestURL.origin,
      'X-XSRF-TOKEN': csrfToken.value || '',
    }
  })
}
